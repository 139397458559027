import React from "react";
import WorkCard from "./WorkCard";
import Ledgerly from './images/ledgerlyv2.png';
import WeatherApp from './images/weather-app.png';
import ImageSearch from './images/image-search.png';
import LaFonda from './images/lafonda.png';

function Work() {
    return (
        <div className="container mx-auto py-20">
            <div className="text-left">
                <h2 className="font-bold tracking-tight text-left text-blue-300">Latest Projects</h2>
                <p className="text-left text-gray-200 text-xl">A collection of personal projects and learning experiments.</p>
                <div className="my-10">
                    <div className="grid md:grid-cols-2 gap-12">
                    <WorkCard 
                        image={Ledgerly} 
                        title="Ledgerly Billing"
                        projectUrl="https://nexjs-dashboard-eta.vercel.app/"
                        imagePosition="center"
                        xMargin="xl:-ml-16"
                        tags="React, Next.js, TypeScript, PostgreSQL"
                        description={
                            <>
                                A dashboard billing application built with React using <strong className="text-blue-200">Next.js</strong> and <strong className="text-blue-200">TypeScript</strong>. It is deployed on <strong className="text-blue-200">Vercel</strong> and uses <strong className="text-blue-200">PostgreSQL</strong> for database management. The application provides a comprehensive interface for managing and viewing billing-related information, 
                                including revenue, customers, invoices, and more.
                            </>
                        }/>
                    <WorkCard 
                        image={WeatherApp} 
                        title="Weather Status Application"
                        projectUrl="https://weather-app-i78z.onrender.com/"
                        imagePosition="center"
                        colOrder="order-2"
                        xMargin="xl:-mr-16"
                        zIndex="z-0"
                        tags="React, OpenWeather API"
                        description={
                            <>
                                A <strong className="text-blue-200">React</strong> based web application 
                                designed to provide users with up-to-date weather information for any 
                                city worldwide. Using the <strong className="text-blue-200">OpenWeather API</strong>, 
                                the app allows users to enter a city and country name to retrieve real-time data 
                                on temperature, humidity, and weather conditions.
                            </>
                        }
                    />
                    <WorkCard 
                        image={ImageSearch} 
                        title="Image Search React and Unsplash API"
                        projectUrl="https://react-images-0n3f.onrender.com/"
                        imagePosition="top"
                        xMargin="xl:-ml-16"
                        tags="React, Unsplash API"
                        description={
                            <>
                                The Image Search Application is a web-based platform built 
                                using <strong className="text-blue-200">React</strong> that 
                                enables users to search and explore a vast collection of images 
                                sourced from the Unsplash API.
                            </>
                        }
                    />
                    <WorkCard 
                        image={LaFonda} 
                        title="Family Recipes"
                        projectUrl="#"
                        imagePosition="top"
                        xMargin="xl:-ml-16"
                        tags="React, Contentful"
                        description={
                            <>  <p>Coming Soon...</p>
                                The family recipe application is a personal project designed to preserve family recipes for future generations. Built with <strong className="text-blue-200">React</strong> and <strong className="text-blue-200">Contentful</strong>.
                            </>
                        }
                    />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Work;
