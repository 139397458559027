
import Hero from './Hero';
import Work from './Work';
import About from './About';
import Footer from './Footer';
import './App.css';
import WorkExperience from './Experience';

function App() {
  return (
    <div className="App bg-gradient-to-r from-blue-900 to-blue-950">
      <Hero />
      <WorkExperience />
      <Work />
      <About />
      <Footer />
    </div>
  );
}

export default App;
