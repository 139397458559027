// import React, { useEffect, useState } from 'react';
// import { gsap } from 'gsap';
import Navigation from './Navigation';
import { ReactTyped } from 'react-typed';
import ContactButton from './Contact'


const Hero = () => {

  // const curtainRef = useRef(null);
  // const heroRef = useRef(null);
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setLoading(false);
  //     gsap.to(curtainRef.current, {
  //       duration: 1,
  //       scaleY: 0,
  //       transformOrigin: "top",
  //       ease: "power2.out"
  //     });
  //   }, 2000); // 2000 milliseconds = 2 seconds

  //   return () => clearTimeout(timeout); // Cleanup on unmount
  // }, []);


    return (
      <div>
        {/* {loading && <div className="loading-screen">Loading...</div>}
        <div ref={curtainRef} className="curtain bg-blue-950"></div> */}
        <div className="container mx-auto h-screen flex items-center justify-start">
        <Navigation/>
        <div className="py-20 text-left lg:w-4/5">
          <h1 className="font-bold tracking-tight text-gray-200">
            <div>Hello!</div> I'm <span className="name-tag whitespace-nowrap" aria-live="polite" aria-atomic="true">
              <ReactTyped
              strings={["Christina Luna"]}
              typeSpeed={80}
              backSpeed={80}
              />
            </span>
          </h1>
          <p className="text-gray-200 text-xl md:w-4/5">
            A Front End Software Engineer passionate about crafting intuitive and visually engaging web experiences. Currently, leading the front-end architecture at <strong className="text-blue-200">Stanley Black and Decker</strong>.
          </p>
          <ContactButton />
        </div>
      </div>
    </div>
    );
  };
  
  export default Hero;
