import React from 'react';
import Tabs from './Tabs';

const WorkExperience = () => {
  const workExperience = [
    {
      title: "Front End Architect",
      company: "Stanley Black and Decker",
      duration: "Jul 2021 - Present",
      description: "As a Front End Architect at Stanley Black & Decker since July 2021, I've enjoyed the chance to elevate our global digital presence from my remote workspace. My focus is on enhancing our front end systems using Drupal, ensuring they're robust, secure, and responsive. I lead efforts to optimize our infrastructure and collaborate closely with external development agencies, guiding them in refining our front end solutions. Working with our offshore Drupal developers, I address maintenance challenges and ensure seamless operation across all platforms. I also plan and execute regular updates to keep everything running smoothly."
    },
    {
      title: "Senior Marketing Front End Developer",
      company: "University of Phoenix",
      duration: "Jan 2021 - Jul 2021",
      description: "As a Senior Marketing Front End Developer at the University of Phoenix, I focused on creating impactful email campaigns that aligned with our brand standards. I managed a comprehensive library of email templates, ensuring they met evolving user engagement needs. Collaborating closely with the marketing team, I developed visually appealing and responsive emails for various campaigns, ensuring seamless integration of content and design. I took pride in conducting thorough quality assurance checks on our emails, addressing any issues prior to deployment to enhance overall campaign effectiveness. Additionally, I provided technical support to the team and contributed to brainstorming sessions for creative solutions. My role also involved leveraging data to optimize targeting and personalization strategies, further strengthening our email marketing initiatives."
    },
    {
        title: "Front End Developer",
        company: "Inizio Evoke",
        duration: "Oct 2015 - Dec 2020",
        description: "As a Front-End Developer at Inizio Evoke, I focused on creating fully responsive and cross-browser-compatible websites using HTML, CSS, JavaScript, and content management systems like Adobe Experience Manager and Drupal. I led the implementation of best practices in web development to deliver high-quality, visually appealing websites that consistently exceeded client expectations.In addition to my development work, I played a key role in expanding our email development team by contributing to the hiring and onboarding processes and streamlining workflows for improved productivity. I also managed project releases, ensuring meticulous tracking and collaboration with stakeholders to meet launch deadlines. Strong communication and collaboration with cross-functional teams helped build lasting client relationships, while my commitment to continuous improvement kept our work innovative and efficient."
      },
      {
        title: "Front End Developer",
        company: "AlertGPS",
        duration: "Oct 2014 - Oct 2015",
        description: "As a Front-End Web Developer at AlertGPS, I led the design, development, and maintenance of the company’s websites, focusing on both aesthetic appeal and functional robustness. I implemented innovative design principles and user experience strategies to enhance our online presence and engagement. In addition to web development, I served as the primary graphic designer, creating captivating visuals for advertising campaigns that aligned with our marketing objectives. I also spearheaded company branding initiatives, ensuring a strong and consistent brand identity across all materials. Collaborating closely with marketing leadership, I provided creative insights to enhance web advertising campaigns. My attention to detail extended to document layout design, ensuring clarity and professionalism in all company communications."
      },
      {
        title: "Compliance Training Developer",
        company: "NYSE Governance Services",
        duration: "Jan 2012 - Oct 2014",
        description: "As a Compliance Training Designer and Developer at NYSE, I designed and developed compliance training programs that aligned with regulatory requirements and company policies. Utilizing instructional design methodologies, I created engaging courseware that enhanced employee learning. I collaborated with cross-functional teams, including software engineers and designers, to integrate compliance training modules into internal and client-facing applications, ensuring a seamless user experience. I also led the design of internal web applications with a focus on functionality and intuitive user interfaces to boost employee productivity. In addition to training sessions that equipped employees with essential skills, I developed detailed documentation for ongoing reference. I played a key role in quality assurance for new software releases, conducting rigorous testing to ensure accuracy, functionality, and compliance, ultimately contributing to a smooth user experience."
      },
  ];

  return (
    <div className="container mx-auto py-20">
      <h2 className="font-bold text-left text-blue-300">Work Experience</h2>
      <div className="my-10">
      <Tabs tabsData={workExperience} />
      </div>
    </div>
  );
};

export default WorkExperience;
