import React, { useState } from "react";
import { FaLinkedin, FaGithub, FaCodepen, FaBars } from 'react-icons/fa';
import Christina from './images/christina-square.jpg';

function Navigation() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header>
      {/* Mobile Navigation Button */}
      <div className="absolute top-4 right-4 md:hidden">
        <button onClick={toggleMenu} className="focus:outline-none">
          <FaBars className="block w-8 text-3xl text-blue-200" />
        </button>
      </div>

      {/* Mobile Menu Overlay */}
      {isMenuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 z-50">
          <div
            className={`fixed top-0 right-0 h-full w-3/4 bg-blue-900 text-black transition-transform duration-600 ease-in-out transform ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'
              }`}
          >
            <button onClick={toggleMenu} className="absolute top-4 right-4 text-4xl text-blue-200">
              &times;
            </button>
            <nav className="flex flex-col items-center h-full space-y-8 text-blue-200">
              <div className="flex flex-col items-center justify-center w-full mt-20 mb-10 p-8">
                <img
                  src={Christina}
                  alt="Edinburgh"
                  className="object-cover rounded-full h-[100px] w-[100px] mb-4 border-2 border-blue-200"
                />
                <p className="text-lg text-white mb-0">Christina Luna</p>
                <p className="text-sm text-white mb-0">Front End Software Engineer</p>
              </div>
              
              <a href="#about" className="text-xl hover:underline">Experience</a>
              <a href="#work" className="text-xl hover:underline">Work</a>
              <a href="#resume" className="text-xl hover:underline">About</a>
              <div className="flex space-x-6">
                <a
                  href="https://www.linkedin.com/in/christina-luna/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-3xl m-2 hover:text-blue-300 transition-transform transform hover:-translate-x-2 will-change-transform ease-in duration-300"
                >
                  <FaLinkedin className="" />

                </a>
                <a
                  href="https://github.com/christinaluna"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-3xl m-2 hover:text-blue-300 transition-transform transform hover:-translate-x-2 will-change-transform ease-in duration-300"
                >
                  <FaGithub />
                </a>
                <a
                  href="https://codepen.io/miss_cluna"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-3xl m-2 hover:text-blue-300 transition-transform transform hover:-translate-x-2 will-change-transform ease-in duration-300"
                >
                  <FaCodepen />
                </a>


              </div>
            </nav>
          </div>
        </div>
      )}
      <nav className="hidden absolute top-4 left-4 md:flex space-x-6 text-lg text-gray-200">
        <a href="#about" className="will-change-transform transition-transform transform hover:text-blue-300 hover:-translate-y-1 p-2 transition-all duration-300 ">Experience</a>
        <a href="#work" className="will-change-transform transition-transform transform hover:text-blue-300 hover:-translate-y-1 p-2 transition-all duration-300">About</a>
        <a href="#resume" className="will-change-transform transition-transform transform hover:text-blue-300 hover:-translate-y-1 p-2 transition-all duration-300">Work</a>
        <a href="#contact" className="will-change-transform transition-transform transform hover:text-blue-300 hover:-translate-y-1 p-2 transition-all duration-300">Resume</a>
      </nav>
      <div className="hidden md:grid fixed top-4 right-4 place-content-center place-items-center">
        <img
          src={Christina}
          alt="Edinburgh"
          className="object-cover rounded-full h-[60px] w-[60px] mb-8 border-2 border-blue-200"
        />
        <a
          href="https://www.linkedin.com/in/christina-luna/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-3xl block text-gray-200 m-2 hover:text-blue-300 transition-transform transform hover:-translate-x-2 will-change-transform ease-in duration-300"
        >
          <FaLinkedin />

        </a>
        <a
          href="https://github.com/christinaluna"
          target="_blank"
          rel="noopener noreferrer"
          className="text-3xl block text-gray-200 m-2 hover:text-blue-300 transition-transform transform hover:-translate-x-2 will-change-transform ease-in duration-300"
        >
          <FaGithub />
        </a>
        <a
          href="https://codepen.io/miss_cluna"
          target="_blank"
          rel="noopener noreferrer"
          className="text-3xl block text-gray-200 m-2 hover:text-blue-300 transition-transform transform hover:-translate-x-2 will-change-transform ease-in duration-300"
        >
          <FaCodepen />
        </a>
      </div>
    </header>
  )
}

export default Navigation;