import React from "react";

function About() {
    return (
        <div className="py-10">
            <div className="container text-gray-200 text-left">
                <h2 className="font-bold tracking-normal text-blue-200">About me</h2>
                <div className="md:grid md:grid-cols-2 md:grid-flow-col md:gap-4 my-10">     
                    <div>
                        <p>
                        I'm a Front End Software Engineer based in Phoenix, AZ, with over 10 years of experience in the industry. Throughout my career, I've had the opportunity to work on a diverse range of projects across sectors including pharmaceuticals, finance, education, and construction tools. I'm well-versed in a variety of technologies such as Drupal, Adobe Experience Manager, Contentful, React, Vue, TypeScript, JavaScript, HTML, CSS, Vercel, and Firebase.</p>
                        <p>I'm passionate about continuous improvement and enjoy collaborating with others to create innovative solutions. My leadership experience has equipped me to effectively manage projects and ensure our front-end architecture meets high standards.</p>
                    </div>
                    <div>
                    <p>When I'm not working, you can find me exploring the great outdoors with my family. I love to travel and experience new places, whether it's hiking in the mountains or relaxing by the beach, capturing memories through photography or working on DIY projects in my garage.</p>
                    <p>Let's connect and explore new opportunities together!</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
