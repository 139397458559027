const Footer = () => {
    return (
      <footer className="bg-blue-800 text-white py-4">
        <div className="container mx-auto text-left">
          <p className="text-sm mb-0">
            Coded in <strong className="text-blue-200">Visual Studio Code</strong> using <strong className="text-blue-200">React</strong> and <strong className="text-blue-200">TailwindCSS</strong>
          </p>
        </div>
      </footer>
    );
  };
  
  export default Footer;
  